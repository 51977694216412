@import 'variables';

@import '../common';

header#page-header #page-logo{
	background:url('/esipro/images/logo.png') no-repeat center center;
	background-size: contain;

}

.my-space-menu {
	ul.menu {
		.menu-item {
			.menu-link {
				border-bottom: 0.0625rem solid $white-color; 
			}

			&:last-child .menu-link { 
				border-bottom: none; 
			}
		}
	}
} 