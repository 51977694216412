.negoc-button{
    font-size: 10px;
    width: 100%;
    font-weight: normal;
    color: #333333 !important;	
}

.ribbon {
	position: absolute;
	left: -5px; top: -5px;
	z-index: 1;
	overflow: hidden;
	width: 75px; height: 75px;
	text-align: right;
	pointer-events:none;
	
	span{
		font-size: 8px;
		font-weight: normal;
		color: #FFF;
		text-transform: uppercase;
		text-align: center;
		line-height: 18px;
		transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		width: 110px;
		display: block;
		background: #79A70A;
		background: linear-gradient(#9BC90D 0%, #79A70A 100%);
		box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
		position: absolute;
	    top: 24px;
	    left: -22px;

		&::before {
			content: "";
			position: absolute; left: 0px; top: 100%;
			z-index: -1;
			border-right: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-left: 3px solid #79A70A;
			border-top: 3px solid #79A70A;
		}

		&::after {
			content: "";
			position: absolute; right: 0px; top: 100%;
			z-index: -1;
			border-left: 3px solid transparent;
			border-bottom: 3px solid transparent;
			border-right: 3px solid #79A70A;
			border-top: 3px solid #79A70A;
		}
	}

	&.green{
		span{
			background: #79A70A;
			background: linear-gradient(#9BC90D 0%, #79A70A 100%);
			&::before{
				border-left: 3px solid #79A70A;
				border-top: 3px solid #79A70A;
			}
			&::after{
				border-right: 3px solid #79A70A;
				border-top: 3px solid #79A70A;
			}
		}
	}

	&.orange{
		span{
			background: #F79E05;
			background: linear-gradient(#F79E05 0%, #8F5408 100%);
			&::before{
				border-left: 3px solid #8F5408;
				border-top: 3px solid #8F5408;
			}
			&::after{
				border-right: 3px solid #8F5408;
				border-top: 3px solid #8F5408;
			}
		}
	}

	&.red{
		span{
			background: #F70505;
			background: linear-gradient(#F70505 0%, #8F0808 100%);

			&::before{
				border-left: 3px solid #8F0808;
				border-top: 3px solid #8F0808;
			}
			&::after{
				border-right: 3px solid #8F0808;
				border-top: 3px solid #8F0808;
			}
		}
	}
}

.article-grid{

	h1{
		font-size: 22px;
		text-transform:inherit;
	    margin-bottom: 20px;
	    margin-top: 10px;
	}

	$primary-color: #008cba;

	.article {
		margin-top: 10px;
	}

	.no-display-price{
		.item-wrapper{
			padding-bottom: 15px !important;
		}
	}

	.item-wrapper {
		padding: 20px;
		border: 2px solid #efefef;
		border-radius: 7px;
		position: relative;
//		padding-bottom: 75px;
		padding-top: 40px;
		box-shadow:0px 0px 4px rgba(0,0,0, 0.6);

		h3 {
		    position: absolute;
		    top: 10px;
		    right: 10px;
		    @media screen and (min-width:1024px){
		    	font-size:12px;
		    }
		}

		div.short-desc{
			font-size:11px;
		}

		&:hover {
			transform:scale(1.03);

		    .img-wrapper {
				img { 
				    -webkit-filter: grayscale(0); 
				    transform : scale(1.1);
				}

				.add-to-cart { 
					bottom: 0; 
					width:60px;
				}
		    }
		}

		.product-quantity{
			bottom: 0px;
			left: 5%;
			width: 90%;
			position: absolute;
			margin-bottom: 0px;
		}	  
	}

	.img-wrapper {
		margin-bottom: 20px;
		position: relative;
		overflow: hidden;

		.add-to-cart {
		    -webkit-transition: all 0.2s ease;
		    transition: all 0.2s ease;
		    background-color: #F6DABC;

		    padding: 16px 8px;
		    bottom: -50px;
		    position: absolute;
		    z-index: 2;
		    color: $black-color;
		    margin: 0 auto;
		    border: none;
		    box-shadow: none;

		    &:hover { 
		    	background-color: #D52B1E; 
		    	color:white !important; 
		    }
		}

		img {
			-webkit-transition: all 0.2s ease;
			transition: all 0.2s ease;
			-webkit-filter: grayscale(1);
			width: 100%;
			min-width:151px;
			min-height:151px;
		}
	}  

	h3 { 
		font-size: 18px; 
	}

	h5 { 
		font-weight: bold;
	    line-height: 17px;
	    margin: 0;
	}
	
	.ttc-price {
	    font-weight: bold;
	    line-height: 17px;
	    margin: 0;
	    font-size: 1.25rem;
	    display:block;
	}	

	p {
		font-size: 13px;
		margin-bottom: 0;
	}	
}


h1 small {
    display: block;
    line-height: 18px;
}