
.off-canvas-wrapper{
	height:100%;

	.off-canvas-wrapper-inner{
		height:100%;

		.off-canvas-content{
			height:100%;

			section{
				&.main-content{
					min-height:calc(100vh - 180px - 85px);	

			        background: #efefef; 

				}
			}
		}
	}
}

.dropdown.menu > li.opens-right > .is-dropdown-submenu {
    top: 100%;
    right: auto;
    left: 0;
	-webkit-box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
	-moz-box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
	box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
    max-height: 500px;
    overflow: auto;
}

#cats-bar{
	position:relative;
	@media screen and (max-width:767px){
		position:absolute;
		top:0;
		width:100%;

		.div-menu-hamburger{
			width: 40px !important;
			height: 40px;
			padding: 0 !important;
			margin: 0 20px;	

			&:last-child{
				i {font-size:19px;}
			}
			
			* {
				color:#11598B;
			}	
		}
	}
}

#search-bar{
	width:100%;
	input{
		max-width:inherit;
	}	
	@media screen and (min-width:768px){
		width:259px;
		float:right;
	}
}


.ais-root__collapsible .filter-title::after{
    content: "\f077";
    position: absolute;
    right: 10px;
    top: 50%;
	transform: translateY(-50%);	
	font: normal normal normal 14px/1 FontAwesome;
}

.ais-root__collapsible.ais-root__collapsed .filter-title::after{
    content: "\f078";
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);	
    font: normal normal normal 14px/1 FontAwesome;
}

.column.critere-filter-block{
	border-bottom:solid 1px grey;
	
	.filter-title{
		padding:3px 15px;
		background:$pink-color;
		color:$white-color;
		position:relative;
		font-size:14px;
		
		.fa-chevron-up{
			display:none;
		}
	}

	.more-values{
		max-height:0;
		opacity:0;
		transition:all 0.5s ease-in;
		overflow:hidden;
	}

	.see-more-values{
	    padding: 0 15px;
	    text-decoration: underline;
	}

	.see-minus-values{
		opacity:0;display:none;
	    padding: 0 15px;
	    text-decoration: underline;
	}

	&.open{
		.see-minus-values{
			opacity:1;
			display:inline-block;
		}

		.see-more-values{
			opacity:0;
			display:none;
		}

		.more-values{
			max-height:30000px;
			opacity:1;
			transition: all 0.5s ease-in;
		}

		.filter-title{
			.fa-chevron-up{
				display:inline-block;
			}

			.fa-chevron-down{
				display:none;
			}
		}
	}
}

#offCanvasRight{
	.column.critere-filter-block .filter-title{
		background: white;
		color: #40B8E1;	
	}	
}

#filters-bar{
    border-radius: 7px;
	-webkit-box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
	-moz-box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
	box-shadow: 0px 10px 60px -15px $pink-color-rgba-70;
}

.dropdown.menu > li.is-dropdown-submenu-parent{
	&:hover{
		> a::after {
		    border-color: $white-color transparent transparent;
		}
	}

	> a::after {
	    border-color: #000 transparent transparent;
	}
} 

.table-cell-sub-cat-filters a{color:$white-color !important;}

#menu-menu-annexe-top li a:hover{
	color:$pink-color !important;
}

/*
.is-drilldown-submenu.is-active {
    transform: none;
    top: 0;
    position: fixed;
}
*/