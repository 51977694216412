@charset 'utf-8';
 
@import 'settings';
@import 'foundation';
@import 'motion-ui';
 
@include foundation-global-styles;
@include foundation-grid;
@include foundation-typography;
@include foundation-button;
@include foundation-forms;
@include foundation-visibility-classes;
@include foundation-float-classes;
@include foundation-accordion;
@include foundation-accordion-menu;
@include foundation-badge;
@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-close-button;
@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-flex-video;
@include foundation-label;
@include foundation-media-object;
@include foundation-menu;
@include foundation-off-canvas;
@include foundation-orbit;
@include foundation-pagination;
@include foundation-progress-bar;
@include foundation-slider;
@include foundation-sticky;
@include foundation-reveal;
@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
@include foundation-thumbnail;
@include foundation-title-bar;
@include foundation-tooltip;
@include foundation-top-bar;
 
@include motion-ui-transitions;
@include motion-ui-animations;

body{
	font-family: 'Roboto Condensed', sans-serif;
	min-height:100vh;
}

@import 'menu';
@import 'headerfooter';
@import 'forms';
@import 'cart';
@import 'account';
@import 'product';

.slider-for .slick-slide img {
    border: 5px solid $pink-color !important;
}

ul.pagination {
    text-align: center;
    margin-top:1rem;

    li.active{
        font-weight : bold;
        color: $pink-color;
    }
}

.page-cms{
	.page-cms-content {
	    padding: 10px 0 30px 0;
	}
}

.article.us__review {
    margin-bottom: 30px;
}

*:focus{
	outline-color:$pink-color;
}

span.price-ht {
    color: $red-color;
    font-weight: normal;
    font-size: 13px;
    text-transform:italic;
}

.tooltip {
    max-width: 20rem;
}

h1, h2, h3{
    text-transform:uppercase;
    color:$red-color;
}

h3{
    text-transform:uppercase;
}

.rated{
    font-size:10px;
    span.raty {
        color: $yellow-color;
        font-size:11px;
    }
}

.bg-pink{
    background-color:$pink-color;
}

.bg-red{
    background-color:$red-color;
}

.bg-blue{
    background-color:$blue-color;
}

.button{
    position: relative;
    display: inline-block;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    font-size: 0.8125rem;
    color: white;
    border-radius: 1.875rem;
    padding: 0.5rem 1.375rem;
    overflow: hidden;
    border: 2px transparent solid !important;
    overflow: visible;
    -webkit-transition: color linear 100ms, background-color linear 300ms;
    transition: color linear 100ms, background-color linear 300ms;

    &.pink-color{
        background-color:$pink-color;
        color:$white-color;
        border:solid 2px transparent !important;

        &:hover{
            border: solid 2px $pink-color !important;
            background-color:transparent;
            color:$white-color;
        }

        &:hover, &:focus{
            outline:0;
        };
    }

    &.blue-color{
        background-color:$blue-color;
        color:$black-color;
        border:solid 2px transparent !important;

        &:hover{
            border: solid 2px $blue-color !important;
            background-color:transparent;
            color:$white-color;
        }

        &:hover, &:focus{
            outline:0;
        };
    }

    &.pink{
        background-color:$pink-color;
        color:$white-color !important;
        &:hover{
            background:white;
            border:solid 2px $pink-color !important;
            color:$black-color !important;
        }
    }

    &.blue{
        background-color:$blue-color;
        color:$black-color !important;

        &:hover{
            background:transparent;
            border:solid 2px $blue-color !important;
        }
    }

    &.red{
        background-color:$red-color;
        color:$white-color !important;

        &:hover{
            background:transparent;
            border:solid 2px $red-color !important;
            color:$black-color !important;
        }
    }

}

.breadcrumb a {
    font-size: 12px;
}

.reveal{
    padding: 0;
    border: none;

    .pop-head{
        background-color: $pink-color;
        color: $white-color;
        text-transform: uppercase;
        font-size: 1.25rem;
        line-height: 1.375rem;
        padding: 0.9375rem;
        font-weight: 700;
        text-align: left;
        border: none;    
    }

    .pop-in-w{
        padding: 1.5625rem;
        overflow: hidden;    
    }

    .close-button{
        color:$white-color;
    }
}

a{
    color:black !important;
}

div.breadcrumb{
    text-transform:uppercase;
}

@media print, screen and (min-width: 40em){
    h1 {
        font-size: 2rem;
    }
    h2{
        font-size: 1.5rem;
    }
    h3{
        font-size: 1.2rem;
    }
}

.content-body{
    background: rgba(255,255,255,1);
    margin-bottom: 30px;
    padding:15px 30px 30px 30px;
}

.text-success{
    color: #43ac6a;
}

.text-danger{
    color:#cc4b37 !important;
}

.pink{
    color:$pink-color !important;
}

.red{
    color:$red-color !important;
}

.blue{
    color:$blue-color;
}

.tooltip {
    max-width: 20rem;
}

.slick-prev:before, .slick-next:before{
    color:$red-color;
}

.see-minus-values, .see-more-values{
    cursor: pointer;
}


.article-grid .item-wrapper .product-quantity{
    opacity:0;
    transition:all 0.5s ease-in;
}

.article-grid .item-wrapper:hover .product-quantity{
    opacity:1;
    transition:all 0.5s ease-in;
}

.search-input .twitter-typeahead{
    display:block !important;
}

.typeahead {
  background-color: #fff;
}

.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
     -moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999
}

#search-bar .tt-menu{
    max-height: 700px;
    overflow: auto;
}

.tt-menu {
  width: 422px;
  margin: 12px 0;
  padding: 8px 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  -webkit-border-radius: 8px;
     -moz-border-radius: 8px;
          border-radius: 8px;
  -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.2);
     -moz-box-shadow: 0 5px 10px rgba(0,0,0,.2);
          box-shadow: 0 5px 10px rgba(0,0,0,.2);

    right: -60px !important;
    left : initial !important;
}

.tt-suggestion {
  padding: 3px 10px;
  font-size: 14px;
  line-height: 18px;
  color : $pink-color;
}

.tt-suggestion:hover {
  cursor: pointer;
  color: #fff;
  background-color: $red-color;
}

.tt-suggestion.tt-cursor {
  color: #fff;
  background-color: $red-color;

}

.tt-dataset-products div.tt-suggestion div{
    display: table-cell !important;
    padding: 5px;
    vertical-align:middle;

    &:nth-of-type(1){
        width:55px;
    }

    &:nth-of-type(2){
        width:245px;
    }

    &:nth-of-type(3){
        width:100px;
        font-size : 11px;
    }

    img{
        width : 45px;
        height : 45px;
    }
}

.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 14px;
}


.search-input .empty-message{
    padding: 5px 10px;
    text-align: center;    
    color : $pink-color;    
}

#modal-cart{
    overflow : visible;
    
    &.reveal .pop-in-w{
        overflow:visible;
    }

    .twitter-typeahead{
        display:inline !important;


        .tt-menu {
            left: 0px !important;
            width: 444px;
        }    
    }
}

.pagination{
    margin:10px 0px;
}
.article-grid .position-left{
    width:300px;
    -webkit-transform: translateX(-300px);
    transform: translateX(-300px);
}


.off-canvas{
    visibility:hidden;
}

body[cz-shortcut-listen="true"] .off-canvas{
    visibility:visible;
}

.article-grid .position-left.is-open ~ .off-canvas-content{
    -webkit-transform: translateX(300px);
    transform: translateX(300px);    
}

.article-grid .position-left.is-transition-push::after{
    box-shadow:none;
}
.article-grid .off-canvas-absolute{
    background:white;
}
.article-grid .off-canvas-absolute.is-open {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
}


.ais-hits--item em {
    color: #000000 !important;
    font-style: normal;
    background-color: $pink-color !important;
    padding: 2px 0 2px 2px;
}

ul.dropdown-pane{
    padding: 0;
    margin:0;
    width: 150px;    

    > li {
        display: block;
        padding: 10px;

        > a{
            width:100%;
            height : 100%;
        }
    }

}
li.label{
    background:white;

    &:hover{
        background:#cecece;
    }
}

.label.h-warning:hover{
    background: #ffae00;
    color: #0a0a0a;    
}

.label.h-alert:hover{
    background: #cc4b37;
    color: #fefefe;    
}

.button.dropdown:hover::after{
    border-color: black transparent transparent !important;
}

table {
    font-size : 12px;

    th{
        font-size : 13px;
        font-weight : bold;
    }
}